@import 'util/mixins';
@import 'components/mixins';
@import 'components/sprite';

$drop-shadow: 15px 15px 45px 0px rgba(10, 10, 10, 0.12); //0px 7px 20px -4px rgba(0,0,0,0.3);


$border-radius: 255px 18px 225px 5px/10px 225px 8px 255px; //255px 25px 225px 15px/15px 225px 15px 255px

$grid-padding: 100px;//120px;

//font-family: 'Amatic SC', cursive;
//font-family: 'Roboto', sans-serif;


//  STYLES
//  -----------------------------
//
//  Table of Contents:
//
//   1. Global
//   2. Breakpoints
//   3. The Grid
//   4. Base Typography
//   5. Typography Helpers
//   6. Abide
//   7. Accordion
//   8. Accordion Menu
//   9. Badge
//  10. Breadcrumbs
//  11. Button
//  12. Button Group
//  13. Callout
//  14. Card
//  15. Close Button
//  16. Drilldown
//  17. Dropdown
//  18. Dropdown Menu
//  19. Forms
//  20. Label
//  21. Media Object
//  22. Menu
//  23. Meter
//  24. Off-canvas
//  25. Orbit
//  26. Pagination
//  27. Progress Bar
//  28. Responsive Embed
//  29. Reveal
//  30. Slider
//  31. Switch
//  32. Table
//  33. Tabs
//  34. Thumbnail
//  35. Title Bar
//  36. Tooltip
//  37. Top Bar


//  0. DW specific
// ----------------------
// .dw-error{
//     display: none !important;
// }


//  1. Global
// ----------------------

a.anchor{
    position: absolute;
    top: 0;
    left: 0;
}


html.has-open-menu{
  position: fixed;
  top:0;
  right:0;
  bottom:0;
  left:0;
  height: 100%;
  max-height: 100%;
}

// .grid-container .small-12 {
//     @include breakpoint(small only){
//         margin-bottom: rem-calc(48px);
//     }
// }

.grid-container{
    position: relative;
}

.grid-margin-x
{

    padding-bottom: rem-calc(48px);//rem-calc(48px);
    padding-top: rem-calc(48px);

    @include breakpoint(medium){
        // padding-bottom: rem-calc(map-get($grid-column-gutter, large));
        // padding-top: rem-calc(map-get($grid-column-gutter, large));
        padding-bottom: rem-calc(40px); //rem-calc(80px);//rem-calc($grid-padding);
        padding-top: rem-calc($grid-padding);
    }

    // @include breakpoint(medium){
    //     margin-bottom: rem-calc(map-get($grid-column-gutter, medium));
    // }
    //
    // @include breakpoint(small only){
    //
    //     & > .cell {
    //         margin-bottom: rem-calc(32px);
    //     }
    //
    //     // .small-12.cell:last-child{
    //     //     margin-bottom: 0;
    //     // }
    // }
}

.grid-container.same-bgcolor .grid-margin-x{
    padding-top: 0;
    padding-bottom: 0;
}

.transparent-background.half-column.with-image + div:not(.half-column) .same-bgcolor{
    padding-top: 4rem;
}

.grid-margin-x h1:first-of-type{
    margin-top: 0;
}


.cell{
    position: relative;
    overflow: hidden;
    z-index: 99;
    //padding: rem-calc(map-get($grid-margin-gutters , small)) 0;

    // padding-bottom: rem-calc(map-get($grid-margin-gutters , small));
    //
    // @include breakpoint(medium){
    //     // padding: rem-calc(map-get($grid-margin-gutters , medium)) 0;
    //     padding-bottom: rem-calc(map-get($grid-margin-gutters , medium));
    // }


    // SLETTES???
    // @include breakpoint(large){
    //     //padding: rem-calc(map-get($grid-margin-gutters , large)) 0;
    //     padding-bottom: rem-calc(map-get($grid-margin-gutters , large));
    // }
    //
    // @include breakpoint(small only){
    //     &:last-child{
    //         padding-bottom: 0;
    //     }
    // }

    @include breakpoint(small){
        padding-bottom: rem-calc(map-get($grid-margin-gutters , small));
    }

    @include breakpoint(medium){
        padding-bottom: rem-calc(map-get($grid-margin-gutters , medium));
    }

    @include breakpoint(large){
        padding-bottom: rem-calc(map-get($grid-margin-gutters , large));
    }


    @include breakpoint(small only){
        &:last-child{
            padding-bottom: 0;
        }

    }

}

// .grid-margin-x + .grid-margin-x,
// .grid-padding-x + .grid-padding-x{
//     padding-top: 0;
//     margin-top: rem-calc(-48px);
// }

.grid-padding-x{

    &.image-and-text{
        @include breakpoint(medium down){
            padding-bottom: rem-calc(80px);
        }
    }

    &.image-and-text .cell:nth-child(even){
        @include breakpoint(small only){
            padding-top: rem-calc(48px);
        }

        @include breakpoint(medium only){
            padding-top: rem-calc(80px);
        }

    }
}


div[class*="-page"]{ //frontpage / suppage
    background-color: #fff;
}

#main{
    position: relative;
    overflow: auto;
    min-height: 115px;
    // padding-top: rem-calc(map-get($grid-column-gutter, small));
    // @include breakpoint(medium){
    //     padding-top: rem-calc(map-get($grid-column-gutter, large));
    // }
}

#main.content-empty{
    min-height: inherit;
}

#main.empty,
.top-content-empty{
    padding-top: 100px;
    @include breakpoint(medium){
        padding-top: 200px;
    }
}

.page-intro .cell.no-intro{
    padding-bottom: map-get($grid-column-gutter, small);
    @include breakpoint(medium){
        padding-bottom: map-get($grid-column-gutter, medium);
    }
    @include breakpoint(large){
        padding-bottom: map-get($grid-column-gutter, large);
    }
}


.with-image{
    background-repeat: no-repeat;
    // background-attachment: fixed;
    background-size: 500px; //700px
    background-position: left top;
}

.insta-section{
    padding-top: rem-calc($grid-padding);
    // @include xy-gutters(-20, padding, left right, true);
    padding-bottom: rem-calc($grid-padding);
}

#insta-feed{
    padding-top: 2rem;
    padding-bottom: 0;
}

.insta-section #insta-feed div{
    @include xy-cell(6);
    // padding-bottom: 4rem;
    margin-bottom: 1rem;
    margin-top: 1rem;

    @include breakpoint(medium) {
        @include xy-cell(3);
    }

    @include breakpoint(large) {
        @include xy-cell(20%);
    }

    a{
        display: block;
    }
}


.footer{

    @include breakpoint(medium){
        .cell{
            padding-bottom: 0;
        }
    }

    //Textcolors
    p{
        margin-bottom: 30px;


        @include breakpoint(medium){
            &:last-child{
                margin-bottom: 0;
            }
        }
    }

    a,
    p a,
    li a{
        color: $white;
    }

    a img:hover{
        border-bottom: none !important;
    }

    padding-top: $grid-padding/2;
    // padding-bottom: $grid-padding/2;
    padding-bottom: 20px;

    @include breakpoint(medium){
        padding-top: $grid-padding;
    }

    strong{
        display: block;
        // margin-bottom: 15px;

        @include breakpoint(small only){
            margin-bottom: initial;
        }
    }


    .wave{
        margin-bottom: 20px;
    }



    .we-made-this {
        margin-top: rem-calc(map-get($grid-column-gutter, small));

        @include breakpoint(large){
            margin-top: rem-calc(map-get($grid-column-gutter, large));
        }

        a{
            font-size: rem-calc(11px);
            font-weight: 100;
        }
    }

}


.post-footer{
    color: $dark-gray;
    a{
        color: $dark-gray;
        &:hover{
            text-decoration: none;
            color: $black;
        }
    }

    img.insta-icon{
        margin-left:7px;
    }

}

img.insta-icon{
    width:18px;
    height:18px;
    margin-right:7px;
}


.primary-background{
    a,
    p a,
    li a{
        color: $white;
        :hover,
        :focus{
            color:$white;
            border-bottom:1px solid $white;
        }
    }
}

//  5. Typography Helpers
// ----------------------





// 11. Button
// ----------------------

.button{
    min-width: 315px;
    font-weight: 400;
    font-style: italic;
}

.button.fill-area{
    width: 100%;
}

.top-content .button{
    min-width: 200px;
    text-align: left;
}

.button.secondary{
    border-width:$button-hollow-border-width;
    border-style:solid;
    border-color: $white;
}


a.button {
    margin-right:20px;
    &:last-child{
        margin-right: 0;
    }
}


.button.hollow.white {
    // @include button($background: $white, $background-hover: $primary-color, $color: $primary-color, $style: hollow);
    border-width:$button-hollow-border-width;
    border-style:solid;
    border-color: $white;
    color: $white;
        &:hover,
        &:focus{
            background-color: $white;
            color: $primary-color;
        }
}

// .secondary-background .button.white.hollow {
//     @include button($background: $white, $background-hover: scale-color($primary-color, $lightness: 5%), $color: $primary-color, $style: hollow);
//
//     &:hover,
//     &:focus{
//         border-color: $primary-color;
//         color: $primary-color;
//     }
//
// }

.primary-background .button.white.hollow {
    @include button($background: $white, $background-hover: scale-color($secondary-color, $lightness: 5%), $color: $secondary-color, $style: hollow);

    &:hover,
    &:focus{
        border-color: $secondary-color;
        color: $secondary-color;
    }

}

.primary-background,
.secondary-background {
    .button{
        border:2px solid $white;
    }

    .button:hover,
    .button:focus {
        border:2px solid $white;
    }
}



// 14. Card
// ----------------------

.card .button{
    min-width: auto;
}

.card .large .button{
    text-transform: none;
}



// 32. Table
// ----------------------

tr{
    border-bottom: 1px solid $primary-color;
}

.primary-background
{
    tr{
        border-bottom: 1px solid #fff !important;
    }

    th{
        color: $white;
    }
}



/*----------------------------------------------

Backgrounds

------------------------------------------------*/

// div[class*="-background"]{
//     border-radius: $border-radius;
// }

// div[class*="-background"]{
//     padding: rem-calc(25px);
// }

.primary-background
{
    background-color: $primary-color;

    // &.transparent{
    //     background-color: rgba($primary-color, 0.85);
    // }

    &.transparency-100{  background-color: rgba($primary-color, 1);     }

    &.transparency-90{   background-color: rgba($primary-color, 0.90);  }

    &.transparency-80{   background-color: rgba($primary-color, 0.80);  }

    &.transparency-70{   background-color: rgba($primary-color, 0.70);  }

    &.transparency-60{   background-color: rgba($primary-color, 0.60);  }

    &.transparency-50{   background-color: rgba($primary-color, 0.50);  }

    &.transparency-40{   background-color: rgba($primary-color, 0.40);  }

    &.transparency-30{   background-color: rgba($primary-color, 0.30);  }

    &.transparency-20{   background-color: rgba($primary-color, 0.20);  }

    &.transparency-10{   background-color: rgba($primary-color, 0.10);  }
}



.secondary-background
{
    background-color: $secondary-color;

    // &.transparent{
    //     background-color: rgba($secondary-color, 0.85);
    // }

    &.transparency-100{  background-color: rgba($secondary-color, 1);     }

    &.transparency-90{   background-color: rgba($secondary-color, 0.90);  }

    &.transparency-80{   background-color: rgba($secondary-color, 0.80);  }

    &.transparency-70{   background-color: rgba($secondary-color, 0.70);  }

    &.transparency-60{   background-color: rgba($secondary-color, 0.60);  }

    &.transparency-50{   background-color: rgba($secondary-color, 0.50);  }

    &.transparency-40{   background-color: rgba($secondary-color, 0.40);  }

    &.transparency-30{   background-color: rgba($secondary-color, 0.30);  }

    &.transparency-20{   background-color: rgba($secondary-color, 0.20);  }

    &.transparency-10{   background-color: rgba($secondary-color, 0.10);  }
}



.gray-background
{
    background-color: $light-gray;

    // &.transparent{
    //     background-color: rgba($light-gray, 0.85);
    // }

    &.transparency-100{  background-color: rgba($light-gray, 1);     }

    &.transparency-90{   background-color: rgba($light-gray, 0.90);  }

    &.transparency-80{   background-color: rgba($light-gray, 0.80);  }

    &.transparency-70{   background-color: rgba($light-gray, 0.70);  }

    &.transparency-60{   background-color: rgba($light-gray, 0.60);  }

    &.transparency-50{   background-color: rgba($light-gray, 0.50);  }

    &.transparency-40{   background-color: rgba($light-gray, 0.40);  }

    &.transparency-30{   background-color: rgba($light-gray, 0.30);  }

    &.transparency-20{   background-color: rgba($light-gray, 0.20);  }

    &.transparency-10{   background-color: rgba($light-gray, 0.10);  }
}



.white-background
{
    background-color: $white;

    // &.transparent{
    //     background-color: rgba($white, 0.85);
    // }

    &.transparency-100{  background-color: rgba($white, 1);     }

    &.transparency-90{   background-color: rgba($white, 0.90);  }

    &.transparency-80{   background-color: rgba($white, 0.80);  }

    &.transparency-70{   background-color: rgba($white, 0.70);  }

    &.transparency-60{   background-color: rgba($white, 0.60);  }

    &.transparency-50{   background-color: rgba($white, 0.50);  }

    &.transparency-40{   background-color: rgba($white, 0.40);  }

    &.transparency-30{   background-color: rgba($white, 0.30);  }

    &.transparency-20{   background-color: rgba($white, 0.20);  }

    &.transparency-10{   background-color: rgba($white, 0.10);  }
}



// Box Strokes
// ----------------------

.primary-stroke
{
    border-color: $primary-color;
}

.secondary-stroke
{
    border-color: rgba($secondary-color, .4);
}

.white-stroke
{
    border-color: $white;
}


.gray-stroke
{
    border-color: $light-gray;
}

// div[class*="-stroke"]{
//     border-width: 1px;
//     border-style: solid;
// }


// div[class*="-12"] .primary-background{ // ^ = starter med     * = wildcard
//     margin-bottom: rem-calc(30px);
// }


.background-image div[class*="-background"]{

    padding: rem-calc(15px);

    margin-top: rem-calc(35px);
    margin-bottom: rem-calc(35px);

    @include breakpoint(medium){
        padding: rem-calc(35px);
        margin-top: rem-calc(70px);
        margin-bottom: rem-calc(70px);
    }

    .grid-margin-x{
        padding: 0;
    }

    &.with-icon{
        @include breakpoint(medium){
            margin-top: rem-calc(70px);
        }
    }

    p:last-child{
        margin-bottom: 0;
    }

}


// -- Frame
.frame-top-left,
.frame-top-right,
.frame-bottom-left,
.frame-bottom-right{
    position:absolute;
    width: 30px;
    height: 30px;
}
.frame-top-left{
    top:0;
    left:0;
    border-top:1px solid #cacaca;
    border-left:1px solid #cacaca;
}

.frame-top-right{
    top:0;
    right:0;
    border-top:1px solid #cacaca;
    border-right:1px solid #cacaca;
}

.frame-bottom-left{
    bottom:0;
    left:0;
    border-bottom:1px solid #cacaca;
    border-left:1px solid #cacaca;
}

.frame-bottom-right{
    bottom:0;
    right:0;
    border-bottom:1px solid #cacaca;
    border-right:1px solid #cacaca;
}

@include breakpoint(medium down){
    .framed.with-image img{
        margin-bottom: rem-calc(map-get($grid-column-gutter, medium));
    }
}


.cell.with-frames,
.cell.with-frames:last-child{
    padding: 4rem;
}

.with-image + .highlighted-section.framed .grid-x {
    padding-top: rem-calc(map-get($grid-column-gutter, small));
    padding-bottom: rem-calc(map-get($grid-column-gutter, small));

    @include breakpoint(medium){
        padding-top: rem-calc(map-get($grid-column-gutter, medium));
        padding-bottom: rem-calc(map-get($grid-column-gutter, medium));
    }

    @include breakpoint(large){
        padding-top: rem-calc(map-get($grid-column-gutter, large));
        padding-bottom: rem-calc(map-get($grid-column-gutter, large));
    }

}
// #end--Frame



.video-gallery{
    position: relative;
}

.play-icon{
    pointer-events:auto;
}

.play-icon:after{
    content:"";
    // content: attr(data-after);
    pointer-events:auto;
    display: block;

    @include absolute-center;
    cursor: pointer;
    border-radius: 75px;
    width: 100px;
    height: 100px;
    box-shadow: $drop-shadow;

    background-color: rgba(255, 255, 255, .68);
    background-image: url(../img/icons/icon-play.svg);
    background-position: calc(50% + 5px) center;
    background-repeat: no-repeat;
    background-size: 30px;

    transition:all 100ms ease-in-out;

    @include breakpoint(large){
        width: 150px;
        height: 150px;

        background-size: 40px;
    }

    &:hover{
        background-color: rgba($white, 1);
        background-size: 43px;
    }

}

// .play-icon{
//     @include absolute-center;
//     cursor: pointer;
//     border-radius: 75px;
//     width: 100px;
//     height: 100px;
//     box-shadow: $drop-shadow;
//
//     background-color: rgba(255, 255, 255, .68);
//     background-image: url(../img/icons/icon-play.svg);
//     background-position: calc(50% + 5px) center;
//     background-repeat: no-repeat;
//     background-size: 30px;
//
//     transition:all 100ms ease-in-out;
//
//     @include breakpoint(large){
//         width: 150px;
//         height: 150px;
//
//         background-size: 40px;
//     }
//
//     &:hover{
//         background-color: rgba($white, 1);
//         background-size: 43px;
//     }
//
// }

.background-image{
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    // width: 100%;

    &.fixed{
    //@include breakpoint(medium){
        background-attachment: fixed;
    }

    // .grid-x.grid-margin-x{
    //     margin-bottom: rem-calc(120px);
    // }

    .grid-x{
        min-height: 40vh;
    }

}

// .gray-background.highlighted-section + .gray-background:not(.highlighted-section),
// .transparent-background.highlighted-section + .transparent-background:not(.highlighted-section)
// {
//     padding-top: rem-calc(120px);
// }

.image-title{
    color: $white;
    font-family: $header-font-family;
    font-size: rem-calc(16px);
    font-weight: 400;

    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: $white;
    min-width: 200px;
    max-width: 200px;
    position: absolute;

    &.top-left{
        top: 20px;
        left: 20px;
    }
    &.top-right{
        top: 20px;
        right: 20px;
    }
    &.bottom-left{
        bottom: 20px;
        left: 20px;
    }
    &.bottom-right{
        bottom: 20px;
        right: 20px;
    }

    //stroke colors
    &.primary-color{
        border-bottom-color: $primary-color;
    }
    &.secondary-color{
        border-bottom-color: $secondary-color;
    }
    &.black{
        border-bottom-color: $black;
    }
}


/*
**** TOP CONTENT ****
*/

.top-content{

    .grid-x{
        margin-bottom: 0;

        height:60vh;
        @include breakpoint(medium){
            height: 100vh;
        }
    }

}



.top-content.background-image{
    height: 60vh;
    @include breakpoint(medium){
        height: 100vh;
    }
}


.front-page .top-content{

    .grid-x{
        height: 100vh;
    }

    &.background-image{
        height: 100vh;
    }


}




/*************
** HAX - vh on iphone5
** https://gist.github.com/pburtchaell/e702f441ba9b3f76f587
*************/

/**
 * iPhone 5
 * You can also target devices with aspect ratio.
 */
@media screen and (device-aspect-ratio: 40/71) {
  .top-content.background-image,
  .top-content .grid-x
   {
       height: 568px;
       min-height: 568px;
    }
}
/*************/

.top-content{
    position: relative;
    overflow-x: hidden;
}

.top-content .grid-x:first-child .cell
{
    margin-bottom:50px;
}

//font-sizes in editor.scss
.top-content .button{
    padding: 0.5em 1em;
}


.top-shortcuts
{
    position: absolute;
    bottom: 0;
    width: 100%;
    background: $light-gray;
    padding-left: 20px;

    p, ul{
        display: inline;
    }

    p{
        font-family: $header-font-family;
        font-size: rem-calc(16px);
        font-weight: 600;
        text-transform: uppercase;
    }

    ul li{
        display: inline-block;
        margin-left: 0;
        // list-style: none;
        background-image: url("../img/icons/icon-chevron-black.svg");
        background-repeat: no-repeat;
        background-position: 25px center;
        background-size: 8px 12px;
        transition:all 0.25s ease-in-out;

        &:hover{
            background-image: url("../img/icons/icon-chevron-green.svg");
            background-position: 35px center;
        }
    }

    ul li a{
        display: inline-block;
        padding:15px;
        // min-width: 170px;
        margin-left:40px;
    }

}

ul.links-list {
    list-style: none;
    margin-left: 0;
    li{
        padding-left: 30px;
        background-image: url("../img/icons/icon-chevron-green.svg");
        background-repeat: no-repeat;
        background-size: 8px 12px;
        // background-size:4.53px 9.15px;
        transition:all 0.25s ease-in-out;
        background-position: left center;

        &:hover{
            background-position: 10px center;
        }
    }
}

.product-list-container{
    .button{
        margin-bottom: 0;
    }
}

#product-list{

    @include breakpoint(small only){
        display: none;
    }

    h3{
        text-transform: uppercase;
    }
    // ul:last-of-type {
    //     margin-bottom: rem-calc(map-get($grid-column-gutter, small));
    //
    //     @include breakpoint(medium){
    //         margin-bottom: rem-calc(map-get($grid-column-gutter, medium));
    //     }
    //
    //     @include breakpoint(large){
    //         margin-bottom: rem-calc(map-get($grid-column-gutter, large));
    //     }
    // }
    ul li a{
        color: $dark-gray;
    }

    .cell:first-child{
        padding-bottom: 0;
    }
}


/* The ribbons */

.corner-ribbon{
    width: 240px;
    background: $secondary-color;
    position: absolute;
    top: 34px;
    left: -55px;
    font-family: $header-font-family;
    font-size: rem-calc(20px);
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    line-height: 60px;
    color: $white;
    transform: rotate(-45deg);
    &:active,
    &:hover,
    &:focus{
        color: $white;
        background: scale-color($secondary-color, $lightness: -15%)
    }
}

/* Custom styles */

.corner-ribbon.sticky{
    position: fixed;
}

.corner-ribbon.shadow{
    box-shadow: 0 0 3px rgba(0,0,0,.3);
}

/* Different positions */

.corner-ribbon.top-left{
    top: 34px;
    left: -55px;
    transform: rotate(-45deg);
}

.corner-ribbon.top-right{
    top: 34px;
    right: -55px;
    left: auto;
    transform: rotate(45deg);
}

@include breakpoint(medium down){
    .small-order-2 .corner-ribbon.top-left{
        top: 34px;
        right: -55px;
        left: auto;
        transform: rotate(45deg);
    }
}

.corner-ribbon.bottom-left{
    top: auto;
    bottom: 34px;
    left: -55px;
    transform: rotate(45deg);
}

.corner-ribbon.bottom-right{
    top: auto;
    right: -55px;
    bottom: 34px;
    left: auto;
    transform: rotate(-45deg);
}



// .category-icon{
//     max-width: 100px;
// }
.cat-img-con{
    width: 100px;
    // height: 100px;
    height: 80px;
    margin-bottom: 20px;
    background-repeat: no-repeat;
    background-position: left bottom;
}

.haandholdte{
    // width: 71.03px;
    // height: 28px;
    background-image: url(../img/icons/icon-haandholdte-gray.svg);
    background-size: 71.03px 28px;
}

.walk-behind{
    // width: 83.3px;
    // height: 58.6px;
    background-image: url(../img/icons/icon-walk-behind-gray.svg);
    background-size: 83.3px 58.6px;
}

.ride-on{
    // width: 84.5px;
    // height: 48.9px;
    background-image: url(../img/icons/icon-ride-on-gray.svg);
    background-size: 84.5px 48.9px;
}

.robotklippere{
    // width: 58px;
    // height: 25.1px;
    background-image: url(../img/icons/icon-robotklippere-gray.svg);
    background-size: 58px 25.1px;
}


.redskabsbaerere{
    background-image: url(../img/icons/icon-redskabsbaerere-gray.svg);
    background-size: 70.353px 63.231px;
}

.park--og-vejmaskiner{
    // width: 84.4px;
    // height: 63.19px;
    background-image: url(../img/icons/icon-park--og-vejmaskiner-gray.svg);
    background-size: 84.4px 63.19px;
}

.trucks{
    // width: 74.3px;
    // height: 53.8px;
    background-image: url(../img/icons/icon-trucks-gray.svg);
    background-size: 74.3px 53.8px;
}

.udlejning{
    // width: 83.7px;
    // height: 50.3px;
    background-image: url(../img/icons/icon-udlejning-gray.svg);
    background-size: 83.7px 50.3px;
}

.brugte-maskiner{
    // width: 61px;
    // height: 62.7px;
    background-image: url(../img/icons/icon-brugte-maskiner-gray.svg);
    background-size: 61px 62.7px;
}

.service{
    background-image: url(../img/icons/icon-wrench-gray.svg);
    background-size: 58.933px 53.323px;
}

/*!
PRELOADERS
*/

/* General */
.loader-wrapper {
    background:#fff;
    position:fixed;
    right:0;
    top:0;
    z-index:10000;
    -moz-transition:all 600ms ease-in-out;
    -o-transition:all 600ms ease-in-out;
    -webkit-opacity:1;
    -webkit-transition:all 600ms ease-in-out;
    bottom:0;
    filter:alpha(opacity=100);
    left:0;
    opacity:1;
    overflow:hidden
}
.loader-wrapper.loaded{
    // -moz-opacity:0;
    // -webkit-opacity:0;
    // filter:alpha(opacity=0);
    opacity:0; //0
    visibility:hidden;//visible;
    zoom:1;
    // z-index:1;
    z-index:999;
}
.loader-wrapper span {display: none;}

.loader-wrapper img {
    position: fixed;
    top:50%;
    left:50%;
    max-width: 128px;
    max-height:128px;
    margin-left: -64px;
    margin-top: -128px;
}

.is-mobile .loader-wrapper,
.menu-mobile .loader-wrapper {
    display: none;
}

/* Wave loader */
.loader-wave,
.loader-wave::before {
    width:150px;
    height:15px;
    //background-color:#ff0000;
    // background-image: url(../svg/wave-rounded.svg);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='147 5394.93 66.18 6.141'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;stroke:%230000c4;stroke-linecap:square;stroke-miterlimit:10;stroke-width:2px;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M1,5.141C6.348,5.141,6.348,1,11.7,1s5.348,4.141,10.7,4.141S27.742,1,33.09,1s5.348,4.141,10.7,4.141S49.135,1,54.483,1s5.348,4.141,10.7,4.141' transform='translate%28147 5394.93%29'/%3E%3C/svg%3E");
    background-repeat: repeat-x;
    background-size: 50%;
    transform-origin:center center;
    background-position: center;
	animation: animatedBackground 1s linear infinite;
}

@keyframes animatedBackground {
	from { background-position: 0 0; }
	to { background-position: 100% 0; }
}

.loader-wave {
    width:150px;
    height:15px;
    margin-left:-75px;
    // margin-top: -12px;
    position:absolute;
    top:50%;
    left:50%;
    // animation:preloader-outer 1.5s ease infinite;
}

/* Circle Rotating */
.loader-circle,
.loader-circle::before {
    width:50px;
    height:50px;
    -webkit-border-radius:50%;
    -moz-border-radius:50%;
    border-radius:50%;background:none;
    -webkit-transform-origin:center center;
    -ms-transform-origin:center center;
    transform-origin:center center
}

.loader-circle {
    width:50px;
    height:50px;
    margin-left:-25px;
    margin-top: -25px;
    position:absolute;
    top:50%;
    left:50%;
    -webkit-animation:preloader-outer 1.5s ease infinite;
    -moz-animation:preloader-outer 1.5s ease infinite;
    -o-animation:preloader-outer 1.5s ease infinite;
    animation:preloader-outer 1.5s ease infinite;
}

.loader-circle::before {
    content:"";
    display: block;
     -webkit-animation:preloader 3s ease infinite;
     -moz-animation:preloader 3s ease infinite;
     -o-animation:preloader 3s ease infinite;
     animation:preloader 3s ease infinite;
     border:2px solid #ccc;
     border-bottom:2px solid $primary-color;
 }
@-webkit-keyframes preloader-outer {
    from{opacity:1;-webkit-transform:rotate(0deg)}
    to{opacity:1;-webkit-transform:rotate(360deg)}
}
@keyframes preloader-outer {
    from{opacity:1;transform:rotate(0deg)}
    to{opacity:1;transform:rotate(360deg)}
}
@-webkit-keyframes preloader {
    0%{opacity:.3}
    50%{opacity:1}
    100%{opacity:.3}
}
@keyframes preloader {
    0%{opacity:.3}
    50%{opacity:1}
    100%{opacity:.3}
}

// @media screen and #{breakpoint(medium)} and #{breakpoint(xlarge down)} {
//   // Medium to extra large styles
// }

/* Scroll down */

// .scroll-down {
//     position: absolute;
//     width: 30px;
//     height: 30px;
//     bottom: 20px;
//     left: calc(50% - 15px);
//     border-bottom: none !important;
//     background-image: url(../img/menu-arrow-down.svg);
//     background-repeat: no-repeat;
//     background-position: center;
// }

// .icon .hamburger{
    // @include hamburger($color: $white, $color-hover: $light-gray, $width:22px);
// }

.scroll-down{
    // position: fixed;
    // bottom: 20px;
    width: 45px;
    height: 45px;
    // left: calc(50% - 23px);
    left: 30px;
    border-bottom: none!important;

    position: absolute;
    bottom: 30px;

    .scroll-down-circle{
        transition: all 600ms;
        position: relative;
        width: 45px;
        height: 45px;
        border-radius: 23px;
        transform: rotate(45deg);
        // border: 1px solid rgba($white,.2);

         .arrow {
            transition: all 600ms;
            position: absolute;
            top: 0;

            &:before{
                transition: all 600ms;
                content: '';
                position: absolute;
                top: 12px;
                left: 12px;
                width: 20px;
                height: 20px;
                border-right: 2px solid #fff;
                border-bottom: 2px solid #fff;
            }
        }

        &:hover{
            border-color: rgba($primary-color,.2);
            background-color: $white;
            .arrow:before{
                border-right-color: $primary-color;
                border-bottom-color: $primary-color;
            }
        }
    }
}



/*----------------------------------------------

S C R O L L D O W N  D O T S

------------------------------------------------*/

.top-content .scroll-down-dots{
    position:relative;
    margin-top:150px;
}

.top-content.background-image .scroll-down-dots{
    position:absolute;
    bottom:50px;
    z-index:888;
    left:50%;
    height:0;
    margin-top: 0;
}

.scroll-down-dots{
    a{
      position:absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
      padding:20px;
      display: flex;
      flex-direction:column;
        span{
          list-style: none;
          width: 8px;
          height: 8px;
          margin-bottom: 10px;
          border-radius: 4px;
          background-color:rgb(255, 133, 0);
          animation: pulse 1s ease-in-out infinite;
        }

        span:nth-child(1){
          animation-delay: 0s;
        }

        span:nth-child(2){
          animation-delay: .1s;
        }

        span:nth-child(3){
          animation-delay: .2s;
        }
    }

    @keyframes pulse {
      0% { transform: scale(0.8); opacity: 0.5; }
      50% { transform: scale(1); opacity: 1; }
      100% { transform: scale(0); opacity: 0; }
    }
}


header {
    position: fixed;
    z-index: 999;
	//transition: all 600ms cubic-bezier(0.600, 0.300, 0.000, 1.000); /* custom */
    transition: all 400ms ease-in-out;

    padding:15px 0;
    background: none;

    @include breakpoint(medium){
        padding:25px 0;
    }

    top: 0;
    left: 0;
    right: 0;



    a{
        position: relative;
        display: inline-block;
        // left: 50%;
        // transform: translateX(-50%);
        // z-index: 100;
    }

    .logo {
        //transition: all 600ms cubic-bezier(0.600, 0.300, 0.000, 1.000);
        transition: all 400ms cubic-bezier(0, 0, 0.500, 0.750);

        width: 250px;

        @include breakpoint(medium){
            width: 310px;
            margin-top: 30px;
        }

    }
}

header.header-shrink{
    padding-top:5px;
    // padding-bottom:5px;

    // background: $white;
    // box-shadow: 15px 15px 45px 0px rgba(10, 10, 10, 0.12);

    .menu-toggle{
        box-shadow: 15px 15px 45px 0px rgba(10, 10, 10, 0.12);
    }

    .logo{
        opacity: 0;
        // width:50px;
        // @include breakpoint(medium){
        //     width: 80px;
        // }
        // @include breakpoint(landscape){
        //     @include breakpoint(medium only){
        //         width:60px;
        //     }
        //     @include breakpoint(small only){
        //         width:50px;
        //     }
        // }
    }

}

// header+#main {
//     padding-top: $grid-padding;
//     @include breakpoint(medium){
//         padding-top: 250px;
//     }
// }

/*----------------------------------------------

5. 	MENU

------------------------------------------------*/
// #locations-nav{
//     display: none;
// }


// .show-locations #locations-nav{
//     display: block;
// }
//
// .show-main-nav #main-nav{
//     display: block;
// }

.show-main-nav #locations-nav{
    display: none;
}

.hide-main-nav{
    #main-nav{
        display: none;
    }
    #locations-nav{
        display: block;
    }
}

.menu-inner {
    // position: fixed;
    // top: 0;
    // right: 0;
    // background: $primary-color;
    // z-index: 99;

	width: 0;
	height: 100%;
	position: fixed;
	top: 0;
	right: 0;
	overflow: hidden;
	background: $primary-color;
    z-index: 99;

    //background:linear-gradient(0deg, rgba(0,0,196, 1), rgba(0,0,98, 1));

	// border-left: 1px solid rgba(0,0,0,0.08);
	-webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
	transition: all 600ms cubic-bezier(0.600, 0.300, 0.000, 1.000); /* custom */
	transition-timing-function: cubic-bezier(0.600, 0.300, 0.000, 1.000); /* custom */
}


/*header.menu-is-open .menu-inner { width: 100%;  }*/ /*340px;*/
.menu-is-open .menu-inner { width: 100%;  }

.is-drilldown{
    width: 100%!important
}

nav{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    overflow-y: auto;
	transition: all 0.2s ease;

    @include breakpoint(medium){
        padding: 0 75px;
    }

    @include breakpoint(large){
        padding: 0 150px;
    }
}


nav ul > li > a {
    color: $white;
    font-family: $header-font-family;
    text-transform: uppercase;

    font-size: rem-calc(25px);

    @include breakpoint(medium){
        font-size: rem-calc(45px);
    }

    @include breakpoint(large){
        font-size: rem-calc(75px);
    }
}

nav ul.submenu > li > a {

    @include breakpoint(medium){
        font-size: rem-calc(45px);
    }
}

nav ul > li > a{
    font-style: italic;
}

nav ul > li > a:not(.new-back) {
    font-weight: 900;
}
nav ul > li > a:hover {
     color:scale-color($secondary-color, $lightness: 75%);
}
nav ul > li > a:focus {
    color:$white;
}

nav ul > li > a.new-back {
    padding-bottom: 80px !important;
    width: 50px;
    height: 50px;

    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../img/icons/icon-chevron-white.svg);
    transform: rotate(180deg);
    background-size: 30%;

    @include breakpoint(medium){
        background-size: 45%;
    }
}


nav ul > li.red-notis{
    position: relative;
    &:after{
        content: "1";
        background-color:$alert-color;
        padding: 11px 8px;
        line-height: 0;
        letter-spacing: 0;
        color: #fff;
        font-weight: 600;
        font-size: rem-calc(10px);
        font-family: $header-font-family;
        border-radius: 12px;
        position: absolute;

        transform:scale(0);

        top: 10px;
        left:230px;

        @include breakpoint(medium){
            top: 5px;
            left:380px;
        }

        @include breakpoint(large){
            top: 10px;
            left:630px;
        }

    }

}

.is-off-canvas-open nav ul > li.red-notis:after{
    transform:scale(1);
    transition: all 0.3s cubic-bezier(0.600, 0.000, 0.200, 1.000);
    transition-timing-function: cubic-bezier(0.600, 0.000, 0.200, 1.000);
    transition-delay: 1s;
}


nav ul > li.screw-background{
    position: relative;


    &:after {
      content: "";
      height: 100%;
      width: 320px;
      background-color: $white;
      transform: skewX(-15deg);
      position: absolute;
      left: 20px;
      top: 0;
      z-index: -1;
    }

    a{
        padding-left:40px;
        color: $secondary-color;
        // z-index: 99;
    }
}

nav ul > li.screw-background:hover{

    &:after {
      background-color: rgba($white, .20%);
    }
    a{
        color: $white;
    }

}

.menu-adress{
    position: absolute;
    bottom: 20px;
    display: none;

    @include breakpoint(medium){
        display: block;
        left:75px;
    }

    @include breakpoint(large){
        left:150px;
    }

    color: $white;

    a{
        color: $white;
    }
}


// .drilldown a {
//     background: transparent;
// }
//
// .menu .is-active > a {
//     background: transparent;
// }
// .drilldown a,
// .drilldown li,
// .drilldown .is-drilldown-submenu{
// // .drilldown a,
// // .menu .is-active > a {
//     //background: transparent;
//     background:linear-gradient(0deg, rgba(0,0,196, 1), rgba(0,0,98, 1));
//     // background: $primary-color;
//     background-size: cover;//100vw 100vh;
//     background-attachment:fixed;
// }



.drilldown .is-drilldown-submenu{
    background: transparent;
}

.drilldown .is-drilldown-submenu.is-active{
    // background: #000;
    background:$secondary-color;
}

// .drilldown .is-submenu-item a,
.drilldown a{
    background: transparent;
}

.drilldown .is-drilldown-submenu-item{
    // background: #000000;
    background:$secondary-color;
}

 // .drilldown a,
 .menu .is-active > a {
     background: transparent;
     color:scale-color($secondary-color, $lightness: 75%);
}

.drilldown .js-drilldown-back > a::before{
    border-color: transparent $white transparent transparent;
}

.drilldown .is-drilldown-submenu-parent > a::after{
    border-color: transparent transparent transparent $white;
}



/*----------------------------------------------

6. 	MENU TOGGLE (Hamburger)

------------------------------------------------*/

// .toggle-button{
//
// }

// .menu-text{
//     display: none;
//     position: absolute;
//
//     @include breakpoint(medium){
//         display: inline-block;
//         top: 50%;
//         right: 40px;
//         bottom: auto;
//         transform: translateY(-50%);
//         float: left;
//         width: 70px;
//         text-align: left;
//         cursor: pointer;
//     }
// }


.toggle-button{
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

.menu-toggle {

    // position: absolute;
    // top: 50%;
    transform: translateY(-50%);
    display: block;
    z-index: 100;

    right: 20px;
    width: 50px;
	height: 50px;

    border-radius: 30px;
    background-color: $light-gray;
    box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.2);
    border: 2px solid $secondary-color;
    // box-shadow: 15px 15px 45px 0px rgba($black,.12);

    // @include breakpoint(medium){
	//   width: 40px;
	//   height: 40px;
    //   border-radius: 25px;
    // }

	display: block;
	cursor: pointer;
	overflow: hidden;
	// margin-right: -3px;
	// transition: height 0.3s ease, transform 0.4s ease;
    transition: all 0.3s ease;

    // background-color: $white;



    .menu-icon{
        display: inline-block;
        float: right;
    }
}


/* hamburger icon / animation */
.menu-toggle span.hamburger,
.menu-toggle span.hamburger:after,
.menu-toggle span.hamburger:before {
    content: "";
    display: block;
    height: 3px; //stroke width
    background: $black;
    position: absolute;
    top: 50%; margin-top: -1px;
    left: 50%;

    width: 20px;
    margin-left: -10px; //half the width in minus

    transition: all 0.3s cubic-bezier(0.600, 0.000, 0.200, 1.000);
    transition-timing-function: cubic-bezier(0.600, 0.000, 0.200, 1.000);
    transition-delay: 0.15s;
}

.menu-toggle span.hamburger:before {
    width: 10px;
    right: 85%;
}

.menu-toggle span.hamburger:after { margin-top: -7px; top: 0; transition-delay: 0.27s; }
.menu-toggle span.hamburger:before { margin-top: 7px; top: 0; transition-delay: 0.2s; }

.is-off-canvas-open .menu-toggle span.hamburger,
.is-off-canvas-open .menu-toggle span.hamburger:after,
.is-off-canvas-open .menu-toggle span.hamburger:before {
	transition-delay: 0.12s;
  	transform: translateX(50px);
}
.is-off-canvas-open .menu-toggle span.hamburger:after { transition-delay: 0s; }
.is-off-canvas-open .menu-toggle span.hamburger:before { transition-delay: 0.07s; }



/* cross icon / animation */
.menu-toggle span.cross:before,
.menu-toggle span.cross:after {
	content: "";
	display: block;
	height: 3px; //stroke width
	background: $black;
	position: absolute;
	top: 50%;
    margin-top: -1.5px;
    left: 50%;

	width: 20px;
    margin-left: -10px; //half the width in minus

	transition: transform 0.3s cubic-bezier(0.600, 0.000, 0.200, 1.000);
	transition-timing-function: cubic-bezier(0.600, 0.000, 0.200, 1.000);
  	transform: translateY(-70px) translateX(-70px) rotate(45deg);
	transition-delay: 0.12s;
}

.menu-toggle span.cross:after {
	transition-delay: 0s;
  	transform: translateY(-70px) translateX(70px) rotate(-45deg);
}

.is-off-canvas-open .menu-toggle span.cross:before {
	transition-delay: 0.12s;
  	transform: rotate(45deg);
}
.is-off-canvas-open .menu-toggle span.cross:after {
	transition-delay: 0.24s;
  	transform: rotate(-45deg) !important;
}

.header-shrink{
    .menu-toggle span.hamburger,
    .menu-toggle span.hamburger:after,
    .menu-toggle span.hamburger:before {
        background: $black;
    }
}

#menu .menu-toggle div:first-child{
    float:right;
    display:inline-block
}

#menu .toggle-button{
    z-index: 999;
}

#menu .toggle-button.have-notification{
    &:after{
        position: absolute;
        content: "";
        top: -20px;
        right: 0px;
        background-color: $alert-color;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        transition: transform 0.3s cubic-bezier(0.600, 0.000, 0.200, 1.000);
        z-index: 999;
        transform: scale(0);
    }

}

html.loaded #menu .toggle-button.have-notification:after{
    transform: scale(1);
	transition-delay: 2s;
}

html.loaded .is-off-canvas-open #menu .toggle-button.have-notification:after{
    transform: scale(0);
    transition-delay: .2s;
    // display: none;

}

/*----------------------------------------------

20. Label

------------------------------------------------*/

.primary-background label{
    color: $white;
}



/* ICONS
-------------------------------------- */

// a[class*="icon-"],
// div[class*="icon-"]:before{
//     background-color:$white; //$primary-color;
//     background-repeat: no-repeat;
//     background-position: center;
//     &:hover{
//         background-color:$white;
//     }
// }
//
// a[class*="icon-"]:hover{
//     background-color:$white;
// }
//
//
// .icon
// {
//     background-color: $primary-color;
//     padding: 15px;
//     width: 70px;
//     border-radius: 40px;
// }


[type='submit'], [type='button'] {
    border-radius: $button-radius !important;
}


/************
* SVG
************/

svg.mapmarker{
    stroke:#ffffff;
    fill:transparent;
}

svg.quotationmarks{
    stroke:$secondary-color;
    fill:transparent;
    stroke-width:4px;
    stroke-linecap:round;
    stroke-linejoin:round;
    stroke-miterlimit:10;
}

// //https://codepen.io/jdsteinbach/pen/MYPgPp
// // ul.shortcut-icons.expanded,
// ul.shortcut-icons.expanded li a
// {
//     animation: animIn 1s ease-in-out;
//     transition: transform 1s ease-in-out, opacity 1s ease-in-out; //.3s
//     display: block;
// }
//
// // ul.shortcut-icons.collapsed
// ul.shortcut-icons.collapsed li a
// {
//     animation: animOut 1s ease-in-out;
//     transition: transform 1s ease-in-out, opacity 1s ease-in-out;
//     display: none;
// }
//
// @keyframes animIn {
//   0% {
//     display: none;
//     opacity: 0;
//     height: 0;
//   }
//   1% {
//     display: block;
//     opacity: 0;
//     transform: scale(0);
//   }
//   100% {
//     opacity: 1;
//     height: auto;
//     transform: scale(1);
//   }
// }
//
// @keyframes animOut {
//   0% {
//     opacity: 1;
//     height: auto;
//   }
//   1% {
//     display: block;
//     opacity: 1;
//     transform: scale(1);
//   }
//   100% {
//     opacity: 0;
//     height: 0;
//     display: none;
//     transform: scale(0);
//   }
// }

.logo-container hr
{
    height: 13px;
    border: none;
    margin: 0;
    width: 50px;
    float: left;

    &:before{
        content: " ";
        display: block;
        border-top: 3px solid $white;
        width: 20px;
        margin: 15px auto;
        margin-top: 0;
    }

    @include breakpoint(small only){
        display: none;
    }
}


a.show-shortcuts
{
    display: none;
    float: left;
    clear: left;

    width: 50px;
    height: 50px;
    // margin-top: 60px;
    background-color: $primary-color;
    border-radius: 25px;
    background-size: 65%;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../img/icons/icon-chevron-white.svg);
    &:hover{
        background-color: $white;
        background-image: url(../img/icons/icon-chevron-blue.svg);
    }

}


// stroke before shortcuts
// ul.shortcut-icons:before{
//     content: " ";
//     display: block;
//     // border-top: 3px solid $white;
//     width: 20px;
//     margin: 15px auto;
//     margin-top: 0;
// }


ul.shortcut-icons li{
    position: relative;
    line-height: 0;

    margin-bottom: rem-calc(map-get($grid-column-gutter, small))/2;

    opacity: 1;
    transition: all .75s ease;
}


ul.shortcut-icons li a{
    background-color: $primary-color;
    width: 45px;//50px;
    height: 45px;//50px;
    border-radius: 23px;//25px;
    transition: .25s ease-in-out;
    z-index: 1;
    box-shadow: $drop-shadow;

    &:hover+.arrow{
        left: 68px;

        opacity: 1;
    }

    &.active{
        background-color: $white;
    }
}



figure{
    // margin-bottom: rem-calc(map-get($grid-column-gutter, small));
    // @include breakpoint(medium){
    //     margin-bottom: 0;
    // }
}




.m0{ margin:0 }
.mt0{ margin-top:0 }
.mr0{ margin-right:0 }
.mb0{ margin-bottom:0 }
.ml0{ margin-left:0 }
.mx0{ margin-left:0; margin-right:0 }
.my0{ margin-top:0; margin-bottom:0 }

.grid-container.mb0{
    .grid-x{
        margin-bottom: 0;
    }
}

// .cell+.cell{
//     margin-top:rem-calc(120px);
// }

// img.fit
// {
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
// }

.fit-image{

    width: 100%; // the same width and height as for the <img>
    height: 100%;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &.compat-object-fit {
        background-size: cover;
        background-position: center center;
        display: block;
        border-bottom: 0;

        img { // hide image if object fit is not supported - opacity to 0 for the link area
            opacity: 0;
        }
    }

}


.event-list{

    .event-date,
    .event-title {
        font-family: $header-font-family;
    }


    .event-date{
        color: $white;
        text-align: center;
        line-height: 0;//2.5rem;
        font-size: rem-calc(27px);

        @include breakpoint(medium){
            font-size: rem-calc(50px);
        }
    }

    .event-title {
        font-size: rem-calc(22px);
        font-weight: 600;

        @include breakpoint(medium){
            font-size: rem-calc(30px);
        }
    }

    .event-date,
    .event-details {

        padding-bottom: 20px;

        &:first-child{
            padding-top: 20px;
        }
    }

    .event-month-short{
        font-family: $body-font-family;
        font-size: rem-calc(20px);
        font-weight: 300;
        display: block;
        line-height: 1.5rem;
    }

    .event-details{

    }

    .event-title{

    }
    // div[class*="-background"]{
    //     margin-bottom: 0;
    // }

    // .event{
    //     margin-bottom: rem-calc(32px);
    //     .grid-x.full{
    //         position: relative;
    //         overflow: hidden;
    //
    //     }
    // }



    // @include breakpoint(medium){
    //     .event-date{
    //         font-size: rem-calc(24px);
    //     }
    // }

}

.event-details{
    .event-date{
        position: relative;
        margin-bottom: rem-calc(map-get($grid-column-gutter, small));

        &:before{
            content: "";
            width: 20px;
            height: 20px;
            border-radius: 20px;
            position: absolute;
            display: inline-block;
            margin-top: -5px;

            background: $primary-color url(../img/icons/icon-clock-white.svg) no-repeat center;
            padding: 1rem;
            // background-size: 70%;
            //background-image:url(../img/icons/icon-clock-white.svg);
            background-size: 65%;
        }
        strong{
            padding-left: 40px;
        }
    }
}



/* Images and galleries */
figcaption {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px 20px;
    background-color: $white;
    text-align: left;
}



/*----------------------------------------------

ContentElementFlexbox.cshml

------------------------------------------------*/

// image on top
.grid-x.imageTop div:first-child{
    margin-bottom: rem-calc(map-get($grid-column-gutter, medium));
}



/*----------------------------------------------

ImageCollage.cshml

------------------------------------------------*/

.image-gallery, .single-image{
    text-align: center;

    @include breakpoint(medium){
        min-height: 650px;
    }
}

.image-gallery{

    figure {
        position: relative;
    }

    &>figure {
        display: none;
    }

    figure:first-of-type {
        display: block;
    }

    a {
        border-bottom: 0 !important;
    }
}

.image-gallery{
    a.show-gallery{
        padding: 30px;
        border-radius: 63px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 999;
        background-color: $white;
        display: inline;

        span{
            width: 60px;
            height: 60px;
            display:block;
            text-align:center;
            font-size: .85rem;
        }
    }

}

.image-gallery div.flex-dir-column:nth-child(2){

    display: none;

    @include breakpoint(medium){
        display: block;
    }
}

// .image-gallery div.flex-dir-column
// {
//     @include breakpoint(medium){
//         &:first-child{
//             padding-right: 10px;
//         }
//
//         &:last-child{
//             padding-left: 10px;
//         }
//     }
//
//     @include breakpoint(small only){
//         &:first-child{
//             margin-bottom: 20px;
//         }
//     }
//
//     figure{
//         margin-bottom: 20px;
//         &:last-child{
//             margin-bottom: 0;
//         }
//     }
// }



/*----------------------------------------------

ParagraphImageText.cshml

------------------------------------------------*/

.image-title-text{
    position:absolute;
    color:#fff;
    background-color:$primary-color;
    text-align:center;
    font-size:.75rem;
    padding:15px;

    &.image-title-full{
        width:100%;
        left:0;
        padding:5px;
    }

    // X
    &.image-title-right{
        right: 0;
    }

    &.image-title-center{
        left: 50%;
        transform: translateX(-50%);
    }

    &.image-title-left{
        left: 0;
    }

    // Y
    &.image-title-top{
        top: 0;
    }

    &.image-title-bottom{
        bottom: 0;
    }

}



/*----------------------------------------------

ParagraphSlideshow.cshml

------------------------------------------------*/

.slide-show .slide-show-textbox {

    width: 100%;

    &.overlay{
        @include breakpoint(large){
            position: absolute;
            bottom: 0;
            z-index: 99;
            margin-bottom:0;

            @include breakpoint(portrait) {
                position: static;
                bottom: auto;
            }

        }
    }

}


/*----------------------------------------------

HEADROOM

------------------------------------------------*/
// .headroom {
//     will-change: transform;
//     transition: transform 200ms linear;
// }
// .headroom--pinned {
//     display: block;
//     transform: translateY(0%);
// }
// .headroom--unpinned {
//     display: none;
//     transform: translateY(-100%);
// }



.vh100{ min-height:100vh; }
.vh90{ min-height:90vh; }
.vh80{ min-height:80vh; }
.vh70{ min-height:70vh; }
.vh60{ min-height:60vh; }
.vh50{ min-height:50vh; }
.vh40{ min-height:40vh; }
.vh30{ min-height:30vh; }
.vh20{ min-height:20vh; }
.vh10{ min-height:10vh; }

.vh100,
.vh90,
.vh80,
.vh70,
.vh60,
.vh50,
.vh40,
.vh30,
.vh20,
.vh10{
    @include breakpoint(small only){
        height: auto;
    }
}

p+.button-container{
    padding-top: rem-calc(8px);
}



/*----------------------------------------------

S C R O L L   TO   T O P

------------------------------------------------*/
#back-top {
    color: $secondary-color;
    position: fixed;
    bottom:20px;
    right: 2%;
    z-index: 100;
    display: none;
    cursor: pointer;
}



/*----------------------------------------------

Q U O T E

------------------------------------------------*/
.quote{

    // @include breakpoint(medium){
    //     min-height: 650px;
    // }

    &.cell{
        position: relative;
    }

    .quotationmarks{
        position: absolute;
        z-index: 0;

        height: 30px;
        left: -30px;
        top: 0px;

        @include breakpoint(medium){
            height: 50px;
            left: -100px;
            top: 35px;
        }

    }

    p{
        font-size: rem-calc(25px);
        line-height: rem-calc(31px);

        @include breakpoint(medium){
            font-size: rem-calc(40px);
            line-height: rem-calc(49px);
        }

        font-style: italic;
        font-weight: 600;

        &.quote-source{
            font-family: $body-font-family;
            font-size: rem-calc(16px);
            font-style: normal;
            font-weight: normal;
        }

    }

}



/*----------------------------------------------

L I G H T G A L L E R Y / L I G H T S L I D E R

------------------------------------------------*/

.vmb-gallery {
    .lg-outer .lg-thumb-item.active,
    .lg-outer .lg-thumb-item:hover {
        border-color: $secondary-color;
    }
}

ul.light-slidergallery {
    margin-left: 0;
    list-style: none;

    &.no-slideshow{
        width: 100%;
    }

    img{
        width: 100%;
    }
}

ul.light-slidergallery.no-slideshow > li{
    display: none;
}

ul.light-slidergallery.no-slideshow > li:first-child{
    display: block;
}

// .lSSlideOuter+p,
// .lSSlideOuter+h1,
// .lSSlideOuter+h2,
// .lSSlideOuter+h3,
// .lSSlideOuter+h4,
// {
//     margin-top: rem-calc(map-get($grid-column-gutter, medium));
// }

.image-top .lSSlideOuter
{
    margin-bottom: rem-calc(map-get($grid-column-gutter, medium));
}


.primary-background,
.secondary-background,
.transparent-background{
    a:not(.button).lSPrev,
    a:not(.button).lSNext{
        border-bottom: none;
    }
}

/* TESTS */
.column-info {
    position: absolute;
    background-color: rgba(255,255,255,0.95);
    border: 1px solid #000;
    padding: 20px;
    z-index: 999;
    left: 50%;
    transform: translateX(-50%);
}

// .centered-xy DONT USE - IE FUCKS
// {
//     position: relative;
//     transform: translateX(-50%);
//     right: -50%;
//
//     @include breakpoint(large) {
//         // transform: translate(-50%, -50%);
//         top: 50%;
//     }
// }


.flex{
    @include flex;
}

.paragraph-container
{

    position: relative;
    z-index: 99;
    padding-top: rem-calc(60px);
    padding-bottom: rem-calc(60px);

    //padding-top: rem-calc(map-get($grid-column-gutter, medium))*1.5; //45px + padding = 60px
    // padding-top: rem-calc(100px);

    @include breakpoint(large){
        // padding: rem-calc(50px);
        padding: rem-calc(map-get($grid-column-gutter, large)); //45px + padding = 60px
        padding-top: rem-calc(100px);
        padding-bottom: rem-calc(100px - 14px); //14px = 1rem
    }



    p:last-child,
    div:last-child,
    ul:last-child,
    ul:last-child li:last-child
    {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    @include breakpoint(large){

        max-width: 570px; //450px + 120 (2 x padding i sider)

        &.small
        {
            max-width: 520px; //400px
        }

        &.medium
        {
            max-width: 720px; //600px
        }

        &.large
        {
            max-width: 870px; //750px
        }
    }


    @include breakpoint(large down){

        max-width: 100%;

        &.small,
        &.medium,
        &.large
        {
            max-width: 100%;
        }
    }
}



/************************************
**** Container / Column settings ****
************************************/

/* Container settings */

.grid-container.full,
.grid-container.fluid
{
    // margin-bottom: rem-calc(map-get($grid-column-gutter, medium))*2;

    &.background-image,
    &.last{
        margin-bottom: 0;
    }
}


.grid-container.full .grid-margin-x{
    margin-left: 0;
    margin-right: 0;
}

.grid-padding-x > .cell.background-image {


    @include breakpoint(small only){
        padding-right: 0;
        padding-left: 0;
    }
}

// .gray-background.half-column.with-image+.gray-background.half-column.with-image{
//     margin-top: 30px;
// }

.half-column.with-image{
    // margin-bottom: 30px;

    @include breakpoint(large){
        .cell{
            padding: 0;
        }
    }

    img{
        width: 100%;
        // height: 100%;
    }
}

.half-column.with-image:last-child{
    margin-bottom: 0;
}


// @include breakpoint(landscape){
//     .half-column .cell{
//         min-height: 320px;
//         @include breakpoint(large){
//             min-height: 650px;
//         }
//     }
// }




/* //////////////////////// OBJECT FIT //////////////////////// */

.cell.fit-image{
    height: auto;
    padding-left: 0;
    padding-right: 0;
}

.fit-image img {
    object-fit: cover;
    font-family: 'object-fit: cover;';
    width: 100%;
    // min-height: 320px;
    height: 100%;

    @include breakpoint(large){
        min-height: 650px;
    }
}


/**********************
****  Inline List  ****
**********************/


ul.inline-list{
    margin-left: 0;
    list-style: none;

    li{
        display: inline-block;
    }

}



.primary-background{
    &>.primary-color{
        color:$white;
    }
}

/**************
**** Arrow ****
**************/

$arrow-border: 45px;

.arrow-left,
.arrow-right,
.arrow-up,
.arrow-down {
    position: absolute;
}

.arrow-left {
  width: 0;
  height: 0;
  border-top: $arrow-border solid transparent;
  border-bottom: $arrow-border solid transparent;

  border-right:$arrow-border solid $light-gray;
  top: 60px;
  right: 0;
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: $arrow-border solid transparent;
  border-bottom: $arrow-border solid transparent;

  border-left:$arrow-border solid $light-gray;
  top: 60px;
  left: 0;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: $arrow-border solid transparent;
  border-right: $arrow-border solid transparent;

  border-bottom: $arrow-border solid $light-gray;
  bottom: 0;
  left: 60px;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: $arrow-border solid transparent;
  border-right: $arrow-border solid transparent;

  border-top: $arrow-border solid $light-gray;
  top: 0;
  left: 60px;
}



/* Other Colors based on bg-color */
.primary-background {
     .arrow-left {
         border-right:$arrow-border solid $primary-color;
     }

     .arrow-right {
         border-left:$arrow-border solid $primary-color;
     }

}

.secondary-background{
    .arrow-left {
        border-right:$arrow-border solid $secondary-color;
    }

    .arrow-right {
        border-left:$arrow-border solid $secondary-color;
    }
}

.transparent-background{
    .arrow-left {
        border-right:$arrow-border solid #fff;
    }

    .arrow-right {
        border-left:$arrow-border solid #fff;
    }
}



@include breakpoint(medium down){
    .arrow-left,
    .arrow-right{
        top: auto;
        bottom: 0;
        left: 60px;

        width: 0;
        height: 0;
        border-left: $arrow-border solid transparent !important;
        border-right: $arrow-border solid transparent !important;

        border-top: $arrow-border solid transparent !important;
        border-bottom: $arrow-border solid $light-gray;
    }

    .transparent-background{
        .arrow-left,
        .arrow-right {
            border-bottom:$arrow-border solid #fff;
        }
    }

    .small-order-1{
        // Image is first / to the left - make arrow point up
        .arrow-left,
        .arrow-right{
            width: 0;
            height: 0;
            border-left: $arrow-border solid transparent !important;
            border-right: $arrow-border solid transparent !important;

            border-bottom: $arrow-border solid $light-gray;
        }
    }
}



.logo-collection .grid-x{
    padding:0;
}

.logo-collection .cell{
    @include xy-cell(6);

    padding: 0;

    @include breakpoint(medium){
        @include xy-cell(3);
        padding: rem-calc(map-get($grid-column-gutter, medium)) 0;
    }

    @include breakpoint(large){
        @include xy-cell(auto);
        padding: rem-calc(map-get($grid-column-gutter, large)) 0;
    }
}

// .selected-brands img{
//     float: left;
//     clear: left;
//     margin-bottom: 20px;
//
//     &:nth-child(odd){
//         margin-right:30px;
//     }
//     &:first-child + img,
//     &:nth-child(even){
//         clear: none;
//     }
// }
 .selected-brands {
     .brands{
         width: 40%;
         float: left;
     }

     img, p{
         float: left;
         clear: left;
         margin-bottom: 20px;
     }
 }


/*----------------------------------------------

HighlightedSection.cshml

------------------------------------------------*/

.highlighted-section{

    @include breakpoint(medium)
    {
        .button-container a
        {
            margin-bottom: 0;
        }

        .background-image .cell:not(.with-frames)
        {
            padding-bottom: 0;
        }
    }

    &.framed{
        .grid-x{
            padding-bottom: rem-calc($grid-padding);
        }
    }

    .textbg {
        padding: 30px 30px;
        background: rgba($black, .5);
    }

}


/*----------------------------------------------

Employee.cshml

------------------------------------------------*/


.employee .parent {
  display: block;
  width: 100%;
  height: 300px;
  overflow: hidden;

  margin-bottom: rem-calc(map-get($grid-column-gutter, medium));



}

.employee .child {
    display: block;
  width: 100%;
  height: 100%;
  background-color: black; /* fallback color */
  background-position: center;
  background-size: cover;

  transition: all 2s;

  @include breakpoint(small only){
        margin-bottom: rem-calc(map-get($grid-column-gutter, large));
  }
}

.employee .name{
    display: block;
    margin-bottom: rem-calc(20px);
}

@include breakpoint(small only){
    .employee p:last-of-type{
        margin-bottom: rem-calc(map-get($grid-column-gutter, medium));
    }
}
// REMOVE BEFORE LAUNCH
// @import 'components/inline-stylesheet';
