@import 'util/mixins';
@import 'components/mixins';

@charset "UTF-8";



//  1. Global
// ----------------------


a[href^="tel:"]
{
    text-decoration: none;
    display: inline-block;
}

// Remove outline after click
// a:active, a:focus {
//   outline: 0;
//   border: none;
//   -moz-outline-style: none
// }

//Remove outline after click
#main-nav a:active,
#main-nav a:focus {
  outline: 0;
  border: none;
  -moz-outline-style: none
}


.prometo,
.Prometo{
    font-family: $header-font-family;
}

.roboto,
.Roboto{
    font-family: $body-font-family;
}


//DELETE THIS??
// * > :last-child { margin-bottom: 0 !important; }


p a:hover {
    text-decoration: underline;
}

li a{
    color: $black
}

strong,
.strong{
    font-weight: 500;
}

.extra-strong{
    font-weight: 600;
}

.uppercase{
    text-transform: uppercase;
}



// @include breakpoint(large){
//     p:last-child,
//     p:last-child a:last-child,
//     ul:last-child,
//     ol:last-child
//     {
//         margin-bottom: 0;
//     }
// }

p,
ul,
ol{
    &:last-of-type{
        margin-bottom: 0;
    }
}


h1 span.product-name,
h2 span.product-name,
h3 span.product-name,
h4 span.product-name,
h5 span.product-name,
h6 span.product-name{
    display: block;
    font-family: $body-font-family;
    font-size: rem-calc(15px);
    font-style: normal;
    color: $dark-gray;
    font-weight: 500;
    // line-height: 1.5rem;
    margin-bottom: 1.3rem;
    margin-top: 8px;
}


.large{
    text-transform: uppercase;
    font-weight: 600;
}


//  5. Typography Helpers
// ----------------------

.dw_typography a.full-border{
    border-color: $black !important;
}

a.full-border{
    text-decoration: none;
    display: inline-block;

    border-width: 1px !important;
    border-style: solid !important;
    border-color: $white !important;

    padding: 5px 10px;

    &:hover{
        border-color: $primary-color !important;
    }
}


.uppercase{
    text-transform: uppercase;
}

/* - Text Colors - */

.primary-color,
.green-color
{
    color: get-color(primary);
}



.secondary-color,
.orange-color
{
    color: get-color(secondary);
    // a{
    //     color: get-color(secondary);
    //     border-bottom: 1px solid get-color(secondary);
    //     &:hover{
    //         border-bottom: none;
    //     }
    // }
}



.white-color{
    color: $white;

    // a{
    //     color: $white;
    //     border-bottom: 1px solid $white;
    //     &:hover{
    //         border-bottom: none;
    //     }
    // }

}



.black-color{
    color:$black;
}



.gray-color{
    color:$light-gray;
}



.force-white-text,
.white:not(.button){

    h1, .h1
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6,
    p, ul li, ol li{
        color: $white !important;
    }
    color: $white !important;
    //
    // p, h1, h2, h3, h4, h5, h6, strong{
    //     color: $white !important;
    // }
}



.force-black-text, .black:not(.button){

    h1, .h1
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6,
    p, ul li, ol li{
        color: $black !important;
    }
    color: $black !important;
    //
    // p, h1, h2, h3, h4, h5, h6, strong{
    //     color: $black !important;
    // }
}



h1,
h2,
h3,
h4,
h5,
h6{

    margin-bottom: $header-margin-bottom;

    span.pre-header {
        display: block;
        font-family: $header-font-family;
        font-size: rem-calc(20px);
        text-transform: uppercase;
        font-weight: 100;
        line-height: rem-calc(24px);
    }

}

a img {
    border: none;
}


a{
    i.fa{
        font-size: .95rem;
        padding-left: .5rem;
        transition: all .25s ease-in-out;
    }

    &:hover{
        i.fa{
            padding-left:1rem;
        }
    }
}


// - ul's

// ul{
//     // &:not(.leaders){
//         li{
//             margin-bottom:0.75em;
//         }
//     // }
// }


// Texts on backgrounds
// -------------------

.primary-background {

    p,
    ul > li,
    ol > li{
        color: $white;
    }

    // a,
    // p a,
    // li a
    // {
    //     &:not(.button){
    //         color: $white;
    //         border-bottom-width: 1px;
    //         border-bottom-style: solid;
    //         border-bottom-color: $white;
    //
    //         &:hover{
    //             border-bottom-color: $black;
    //         }
    //
    //     }
    // }
}

.secondary-background {

    p,
    ul > li,
    ol > li{
        color: $black;
    }

    // a,
    // p a,
    // li a
    // {
    //     &:not(.button){
    //         color: $black;
    //         border-bottom-width: 1px;
    //         border-bottom-style: solid;
    //         border-bottom-color: $black;
    //
    //         &:hover{
    //             border-bottom-color: $black;
    //         }
    //
    //     }
    // }
}

.primary-background,
.secondary-background {

    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6
    {
        color: $white;
    }

}




td a,
.primary-background td a,
.secondary-background td a{
    border-bottom: none;
}

/* - /background */



/* - Manchet - */

.manchet, .quote, .lead{
    // font-weight: 400; // Regular
    font-size: rem-calc(17px); //rem-calc(17px);

    @include breakpoint(medium) {
        font-size: rem-calc(20px);
    }
    font-family: $header-font-family;
    font-weight: 400;
}

.dw_typography{
    .manchet, .quote, .lead{
        font-family: $header-font-family;
        font-size: $lead-font-size;
        line-height:$lead-lineheight;

    }
}



/* - Images - */

img,
a,
.image-gallery,
.single-image
{
    &+h1,&+h2,&+h3,&+h4,&+h5,&+h6,
    &+.h1,&+.h2,&+.h3,&+.h4,&+.h5,&+.h6
    {
    //&+.paragraph-container{
        margin-top:2rem
    }
}

.paragraph-container{
    margin-top:2rem;
    margin-bottom:2rem;

}


.top-content h1,
.extra-large
{
    font-weight: 600;
    text-transform: uppercase;
    overflow-wrap: break-word;

    font-size: rem-calc(40px);

    @include breakpoint(medium){
        font-size: rem-calc(85px);
        line-height: rem-calc(85px);
    }

    @include breakpoint(large){
        font-size: rem-calc(100px);
        line-height: rem-calc(100px);
        padding-left: 6px; //avoid letter-cutoff
    }

    span{
        display: block;
        font-style: italic;
        text-transform: none;
        font-weight: 300;
        font-size: .5em;
        line-height: 1.2em;
        // margin-bottom: 1em;
        // margin-top: 8px;
    }

}

.top-content h1 {
    color: $white;
}


.front-page .top-content h1
{
    font-size: rem-calc(40px);

    @include breakpoint(medium){
        font-size: rem-calc(75px);
        line-height: rem-calc(75px);
    }

}
